<template>
	<div class="d-flex flex-column max-height-100">
		<!-- Cabecera -->

		<!-- Info y mapa -->
		<div class="d-flex flex-grow-1 fill-height" :style="{ height: `100vh` }">
			<div v-if="!isSmallScreen" :style="{ width: 'max(' + dinamicWidth + 'px, ' + dinamicWidthPercentage + 'vw)' }">
				<map-panel-info-ais @isHidePanel="changeHidePanel" :aisStops="aisStopsFeatures" :currentmap="currentMap" />
			</div>
			<div v-else :style="{ width: 'max(' + dinamicWidth + 'px, ' + dinamicWidthPercentage + 'vw)' }">
				<map-panel-info-ais @isHidePanel="changeHidePanel" :aisStops="aisStopsFeatures" :currentmap="currentMap" />
			</div>
			<div class="flex-grow-1">
				<map-panel-map-ais :stopsProcessed="stopsProcessed" :currentmap="currentMap" :cepZones="cepZones" />
			</div>
		</div>
	</div>
</template>

<script>
import MapPanelInfoAis from '@/components/operations/map/sections/infoais/MapPanelInfoAis';
import MapPanelMapAis from '@/components/operations/map/sections/map/MapPanelMapAis';
import { SETTINGS_PANEL_ID } from '@/lib/variables/panels';
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

import { BERTHS_LAYER_ID } from '@/components/operations/map/constants/layers';

/** Mixins */
import StopsServiceMixin from '@/mixins/StopsServiceMixin';
import AISServiceMixin from '@/mixins/AISServiceMixin';
import CartoMixin from '@/mixins/CartoMixin.js';
import CepServiceMixin from '@/mixins/CepServiceMixin.js';
import ColorsAuthorityMixin from '@/mixins/ColorsAuthorityMixin.js';
import LayersActiveMixin from '@/mixins/LayersActiveMixin';

/** Creators */
import AISVesselsFeatureCreator from '@/components/operations/map/creators/AISVesselsFeatureCreator';

/** Constants */
import {
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	CEP_ZONES_ID
} from '@/components/operations/map/constants/layers';
import { MAP_AIS_ID } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

const AisChannel = 'aisSentence';
const websokectsConnected = 'Connected';

export default {
	name: 'operationsmapais',

	mixins: [CartoMixin, StopsServiceMixin, AISServiceMixin, CepServiceMixin, ColorsAuthorityMixin, LayersActiveMixin],

	components: {
		//MapPanelHeader,
		MapPanelInfoAis,
		MapPanelMapAis
	},

	props: {
		selectedtab: {
			type: String,
			required: false,
			default: SETTINGS_PANEL_ID
		},
		currentMap: {
			type: String,
			required: false,
			default: MAP_AIS_ID
		}
	},
	data() {
		return {
			isSmallScreen: false,
			dinamicWidth: 350,
			dinamicWidthPercentage: 25,
			stopsProcessed: [],
			aisStopsFeatures: [],
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat,
			initialShipsAISLoaded: false,
			cepZones: []
		};
	},

	beforeDestroy() {
		this.$store.commit('setMapAisLoaded', false);
		this.$store.commit('setContainerHeaderVisibility', true);
	},

	destroyed() {
		this.webSocketUnSubscribeAis();
		this.$puiEvents.$off('map:updated', this.setUpData);
	},

	created() {
		// Responsive map
		window.addEventListener('resize', this.checkScreenSize);
		this.checkScreenSize();
		this.changeHidePanel(false);
		//

		const modeVisualization = this.$store.getters.getMapAisStyleVisualization;
		this.aisVesselsFeatureCreator = new AISVesselsFeatureCreator(modeVisualization);
		this.aisVesselsFeatureCreator.setSearchingTexVesselName(this.$store.state.mappanelais.searchingtext);
		this.aisVesselsFeatureCreator.setIsFilterVisitLayerActive(this.layerAISVisitFilterActive);
		this.aisVesselsFeatureCreator.setIsFilterServiceLayerActive(this.layerAISServiceFilterActive);
		this.aisVesselsFeatureCreator.setIsFilterOtherVesselLayerActive(this.layerAISOtherFilterActive);
		this.setStatusColorsAutority();
		this.setTypeColorsAutority();
		this.setDefaultBackgroundColor();
		this.setDefaultStrokeColor();
	},

	mounted() {
		this.$store.commit('setContainerHeaderVisibility', false);
	},

	computed: {
		puiWebSocketsState() {
			return this.$store.getters.webSocketsState;
		},
		mapAisIsLoaded() {
			return this.$store.getters.getMapAisIsLoaded;
		},
		stopsOpts() {
			this.userQuaysId = this.$store.getters.getUserQuaysId;
			const opts = {
				model: 'stopoperations',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
						{ field: 'berth_id', op: 'in', data: this.userQuaysId }
					]
				}
			};
			return opts;
		}
	},

	methods: {
		checkScreenSize() {
			this.isSmallScreen = window.innerWidth <= 600;
		},
		/**
		 * Cambiar el tamaño del panel
		 */
		changeHidePanel(valor) {
			if (valor) {
				if (this.isSmallScreen) {
					this.dinamicWidth = 20;
					this.dinamicWidthPercentage = 1;
				} else {
					this.dinamicWidth = 42;
					this.dinamicWidthPercentage = 0;
				}
			} else {
				if (this.isSmallScreen) {
					this.dinamicWidth = 0;
					this.dinamicWidthPercentage = 100;
				} else {
					this.dinamicWidth = 350;
					this.dinamicWidthPercentage = 25;
				}
			}
		},
		/**
		 * Get data
		 */
		setUpData() {
			this.setStopsData();
			this.setCepZones();
		},

		/**
		 * Stops
		 */

		async setStopsData() {
			try {
				const pAC = this.setAcceptedStops();
				const pPL = this.setPlannedStops();
				const pAU = this.setAuthorizedStops();
				const pCO = this.setConfirmedStops();
				const pIN = this.setInitiatedStops();
				await Promise.all([pAC, pPL, pAU, pCO, pIN]).then((data) => {
					// Check aisPortCallNumbers, eliminados de prevision los que el ais ya tengan escala
					this.stopsProcessed = [].concat
						.apply([], data)
						.filter((item) => !this.$store.getters.getAisPortCallNumbers.includes(item.portcallnumber));
				});
			} catch (error) {
				console.error(error);
				return [];
			}
		},

		async setAcceptedStops() {
			var opts = structuredClone(this.stopsOpts);
			opts.model = 'stopacceptedoperations';
			opts.filter.rules.push(
				{
					field: 'eta',
					op: 'le',
					data: new Date()
				},
				{
					field: 'etd',
					op: 'ge',
					data: new Date()
				}
			);

			return await this.getStops(opts, STOP_ACCEPTED_LAYER_ID);
		},

		async setPlannedStops() {
			var opts = structuredClone(this.stopsOpts);
			opts.model = 'stopplannedoperations';
			opts.filter.rules.push(
				{
					field: 'eta',
					op: 'le',
					data: new Date()
				},
				{
					field: 'etd',
					op: 'ge',
					data: new Date()
				}
			);
			return await this.getStops(opts, STOP_PLANNED_LAYER_ID);
		},

		async setAuthorizedStops() {
			var opts = structuredClone(this.stopsOpts);
			opts.model = 'stopauthorizedoperations';
			opts.filter.rules.push(
				{
					field: 'etaauth',
					op: 'le',
					data: new Date()
				},
				{
					field: 'etdauth',
					op: 'ge',
					data: new Date()
				}
			);
			return await this.getStops(opts, STOP_AUTHORIZED_LAYER_ID);
		},

		async setConfirmedStops() {
			var opts = structuredClone(this.stopsOpts);
			opts.model = 'stopconfirmedoperations';
			opts.filter.rules.push(
				{
					field: 'etaauth',
					op: 'le',
					data: new Date()
				},
				{
					field: 'etdauth',
					op: 'ge',
					data: new Date()
				}
			);
			return await this.getStops(opts, STOP_CONFIRMED_LAYER_ID);
		},

		async setInitiatedStops() {
			var opts = structuredClone(this.stopsOpts);
			opts.model = 'stopinitiatedoperations';

			return await this.getStops(opts, STOP_INITIATED_LAYER_ID);
		},

		async setInitialShipsAIS() {
			const shipsAIS = await this.getInitialAISVessels();
			var aisPortCallNumbers = [];
			for (let i = 0, stopsLength = shipsAIS.length; i < stopsLength; i++) {
				var berthFeatures = ol2map.getLayerById(BERTHS_LAYER_ID).getSource().getFeatures();
				var featureProperties = this.aisVesselsFeatureCreator._processVessel(shipsAIS[i], berthFeatures);
				if (featureProperties != null) {
					if (featureProperties.portCallNumber) {
						// Comprobar si el elemento ya existe en el array antes de agregarlo
						if (!aisPortCallNumbers.includes(featureProperties.portCallNumber)) {
							aisPortCallNumbers.push(featureProperties.portCallNumber);
						}
					}
					this.aisStopsFeatures.push(featureProperties);
				}
			}
			this.$store.commit('setAisPortCallNumbers', aisPortCallNumbers);
			this.initialShipsAISLoaded = true;

			if (this.puiWebSocketsState.toUpperCase() == websokectsConnected.toUpperCase()) {
				this.webSocketSubscribeAis();
			}
		},
		async setCepZones() {
			var opts = this.cepOpts;
			opts.model = 'cepzone';
			this.cepZones = await this.getCepFeatures(opts, CEP_ZONES_ID);
		},
		/**
		 * Websockets
		 */
		async webSocketSubscribeAis() {
			var berthFeatures = null;
			this.$puiWebSockets.subscribe(
				this.$store.getters.getUser,
				`/topic/${AisChannel}`,
				(info) => {
					let data = JSON.parse(info.body);
					data.otherVessel = !data.isService && !data.portCallNumber;
					data.otherVesselServices = data.isService && !data.isPilot && !data.isTug;
					//console.log('MMSI: ' + data.mmsi);
					//console.log('lat: ' + data.lat + 'lon: ' + data.lon);
					if (ol2map.getLayerById(BERTHS_LAYER_ID) == undefined) {
						return;
					}
					if (!berthFeatures) {
						berthFeatures = ol2map.getLayerById(BERTHS_LAYER_ID).getSource().getFeatures();
					}

					var featureProperties = this.aisVesselsFeatureCreator._processVessel(data, berthFeatures);
					if (featureProperties != null) {
						// Comprobar si el elemento ya existe en el array antes de agregarlo
						if (featureProperties.portCallNumber) {
							// Comprobar si el elemento ya existe en el array antes de agregarlo
							if (!this.$store.getters.getAisPortCallNumbers.includes(featureProperties.portCallNumber)) {
								const aisPortCallNumbers = this.$store.getters.getAisPortCallNumbers.push(featureProperties.portCallNumber);
								this.$store.commit('setAisPortCallNumbers', aisPortCallNumbers);
							}
						}
					}
				},
				{
					portAuthorityId: window.localStorage.getItem('workingPortAuthorityId')
				}
			);
		},

		webSocketUnSubscribeAis() {
			this.$puiWebSockets.unsubscribe(this.$store.getters.getUser, `/topic/${AisChannel}`, {
				portAuthorityId: window.localStorage.getItem('workingPortAuthorityId')
			});
		},

		websocketsIsConnected() {
			if (this.puiWebSocketsState == websokectsConnected.toUpperCase()) {
				return true;
			}
			return false;
		},
		getDateTime() {
			let date = new Date();
			let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
			let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
			let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
			let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
			let string = day + '' + month + '' + date.getFullYear() + '' + hour + '' + minutes;

			return string;
		},
		formatDates(obj) {
			if (obj) {
				if (obj.eta) {
					obj.etaformat = this.$dateTimeUtils.getLocalFormattedDate(obj.eta, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.etd) {
					obj.etdformat = this.$dateTimeUtils.getLocalFormattedDate(obj.etd, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.etc) {
					obj.etcformat = this.$dateTimeUtils.getLocalFormattedDate(obj.etc, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.ata) {
					obj.ataformat = this.$dateTimeUtils.getLocalFormattedDate(obj.ata, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.atd) {
					obj.atdformat = this.$dateTimeUtils.getLocalFormattedDate(obj.atd, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.atc) {
					obj.atcformat = this.$dateTimeUtils.getLocalFormattedDate(obj.atc, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.pba) {
					obj.pbaformat = this.$dateTimeUtils.getLocalFormattedDate(obj.pba, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.afa) {
					obj.afaformat = this.$dateTimeUtils.getLocalFormattedDate(obj.afa, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.fla) {
					obj.flaformat = this.$dateTimeUtils.getLocalFormattedDate(obj.fla, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.pbd) {
					obj.pbdformat = this.$dateTimeUtils.getLocalFormattedDate(obj.pbd, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.lla) {
					obj.llaformat = this.$dateTimeUtils.getLocalFormattedDate(obj.lla, `${this.dateFormat} ${this.timeFormat}`);
				}
				if (obj.builddatetime) {
					obj.builddatetimeformat = this.$dateTimeUtils.getLocalFormattedDate(obj.builddatetime, `${this.dateFormat} ${this.timeFormat}`);
				}
			}
		}
	},

	watch: {
		'$store.state.mappanelais.mapAisIsLoaded'() {
			this.$puiEvents.$on('map:updated', this.setUpData);
			// Get the data
			this.setUpData();
			this.setInitialShipsAIS();

			// Vessel Layer create
			const aisVesselLayer = this.aisVesselsFeatureCreator.getVesselsLayer();
			ol2map.addLayer(aisVesselLayer);
		},

		// Watch para saber cuando ha cambiado searchtext
		'$store.state.mappanelais.searchingtext'(searchtext) {
			this.aisVesselsFeatureCreator.setSearchingTexVesselName(searchtext);
		},

		// Watch para saber cuando ha cambiado el estilo del mapa
		'$store.state.mappanelais.mapStyleVisualization'(value) {
			this.aisVesselsFeatureCreator.setModeVisualization(value);
		},

		// Watch para saber cuando ha cambiado el filtro de visita AIS
		'$store.state.mappanelais.layers.aisVesselGroup.filterAisVisitLayer'(value) {
			this.aisVesselsFeatureCreator.setIsFilterVisitLayerActive(value);
			ol2map.setVisibleFeatureByFilterAIS(
				this.$store.state.mappanelais.searchingtext,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterAisVisitLayer,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterServiceLayer,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterOtherVesselLayer
			);
		},

		// Watch para saber cuando ha cambiado el filtro de servicio AIS
		'$store.state.mappanelais.layers.aisVesselGroup.filterServiceLayer'(value) {
			this.aisVesselsFeatureCreator.setIsFilterServiceLayerActive(value);
			ol2map.setVisibleFeatureByFilterAIS(
				this.$store.state.mappanelais.searchingtext,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterAisVisitLayer,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterServiceLayer,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterOtherVesselLayer
			);
		},

		// Watch para saber cuando ha cambiado el filtro de Other AIS
		'$store.state.mappanelais.layers.aisVesselGroup.filterOtherVesselLayer'(value) {
			this.aisVesselsFeatureCreator.setIsFilterOtherVesselLayerActive(value);
			ol2map.setVisibleFeatureByFilterAIS(
				this.$store.state.mappanelais.searchingtext,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterAisVisitLayer,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterServiceLayer,
				this.$store.state.mappanelais.layers.aisVesselGroup.filterOtherVesselLayer
			);
		},

		// Watch para cuando se añaden aisPortCallNumbers de AIS
		'$store.state.mappanelais.aisPortCallNumbers'() {
			this.$puiEvents.$emit('map:refreshprogress');
			this.setUpData();
		},

		puiWebSocketsState(val) {
			if (val.toUpperCase() == websokectsConnected.toUpperCase() && this.mapAisIsLoaded && this.initialShipsAISLoaded) {
				// Subscribe websockets
				this.webSocketSubscribeAis();
			}
		}
	}
};
</script>

<style lang="postcss">
@import '../styles/app-variables.pcss';
.mappanel {
	&__header {
		border-bottom: 1px solid var(--moderate);
	}
	&--fill-height {
		height: calc(100vh - 52px);
		max-height: calc(100vh - 52px);
	}
}
</style>
